import React, { Component } from 'react';
// import { Link } from 'gatsby';
import withContext from 'store/withContext';

//components
import PostStatsHeader from 'components/Stats/PostStatsHeader';
import MatchBreakdown from 'components/Global/MatchBreakdown';
import PlayerStatsTable from 'components/Stats/PlayerStatsTable'
import StatsTable from 'components/Global/StatsTable';
import PicksBansCard from 'components/Stats/PicksBansCard';
import TopPlayersCard from 'components/Stats/TopPlayersCard';

import PageWrapper from '../styles/matches.css';
// import { ThemeConsumer } from 'styled-components';
// import { isThisSecond } from 'date-fns';

class Schedule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      matchDetailData: null,
      hideOne: 'Hide',
      hideTwo: 'Hide',
      game: 1,
      topPlayers: [],
      itemDetails: null,
      toolTipData: {},
      Xposition: null,
      Yposition: null,
      isLoaded: false,
      isDataLoaded: false
    }
  }

  filterPhase = (date) => {
    if (date.phase === this.state.phase) {
      return true;
    }
  }

  getMatchDetails = (id1, id2) => {
    fetch(`${this.props.context.baseUrl}/matchstats/${id1}/${id2}`)
      .then(response => response.json())
      .then(response => {
        if (!response.error) {
          this.setState({
            matchDetailData: response,
            isDataLoaded: true
          });
        } else {
          this.setState({
            isDataLoaded: true
          })
        }
      });
  }

  getItemDetails = () => {
    fetch("https://cms.smitegame.com/wp-json/smite-api/getItems/1")
      .then(response => response.json())
      .then(response => {
        this.setState({
          itemDetails: response
        });
      });
  }

  toggleOneStats = () => {
    if (this.state.hideOne === 'Hide') {
      this.setState({
        hideOne: 'Show'
      })
    }
    else {
      this.setState({
        hideOne: 'Hide'
      })
    }
  }

  toggleTwoStats = () => {
    if (this.state.hideTwo === 'Hide') {
      this.setState({
        hideTwo: 'Show'
      })
    }
    else {
      this.setState({
        hideTwo: 'Hide'
      })
    }
  }

  loadToolTip = (name, Xposition, Yposition, isLoaded) => {
    this.setState({
      isLoaded: isLoaded
    })
    this.state.itemDetails.find(item => {
      if (item.DeviceName === name) {
        this.setState({
          toolTipData: item,
          Xposition: Xposition,
          Yposition: Yposition,
        })
      }
    }
    )
  }

  filterTeam = (data) => {
    if (data.team === this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team) {
      return true;
    }
  }

  filterTeamTwo = (data) => {
    if (data.team === this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team) {
      return true;
    }
  }
  componentDidMount() {
    this.getMatchDetails(this.props.context.eventId, this.props.location.pathname.split('/')[2]);
    this.getItemDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getMatchDetails(this.props.context.eventId, this.props.location.pathname.split('/')[2]);
      this.getItemDetails();
    }
  }

  render() {
    return (
      <PageWrapper className="match-details">
        {this.state.isDataLoaded && this.state.matchDetailData && this.state.matchDetailData !== null &&
          <div className="match-page-container">
            <PostStatsHeader
              team_1_name={this.state.matchDetailData.team_1_name}
              team_1_kills={this.state.matchDetailData.team_1_kills}
              team_1_gold={this.state.matchDetailData.team_1_gold}
              team_1_score={this.state.matchDetailData.team_1_score}
              team_2_name={this.state.matchDetailData.team_2_name}
              team_2_kills={this.state.matchDetailData.team_2_kills}
              team_2_gold={this.state.matchDetailData.team_2_gold}
              team_2_score={this.state.matchDetailData.team_2_score}
              in_progress={this.state.matchDetailData.in_progress}
              set_duration={this.state.matchDetailData.set_duration}
            />
            <div className="center-wrapper">
              <div className="components-wrapper">
                <div className="game-container">
                  <div className={`game-btn ${this.state.game === 1 ? 'active' : ''}`} onClick={() => this.setState({ game: 1 })}>Game 1</div>
                  <div className={`game-btn ${this.state.game === 2 ? 'active' : ''}`} onClick={() => this.setState({ game: 2 })}>Game 2</div>
                  {this.state.matchDetailData.games[2] &&
                    <div className={`game-btn ${this.state.game === 3 ? 'active' : ''}`} onClick={() => this.setState({ game: 3 })}>Game 3</div>
                  }
                  {this.state.matchDetailData.games[3] &&
                    <div className={`game-btn ${this.state.game === 4 ? 'active' : ''}`} onClick={() => this.setState({ game: 4 })}>Game 4</div>
                  }
                  {this.state.matchDetailData.games[4] &&
                    <div className={`game-btn ${this.state.game === 5 ? 'active' : ''}`} onClick={() => this.setState({ game: 5 })}>Game 5</div>
                  }
                  {this.state.matchDetailData.games[5] &&
                    <div className={`game-btn ${this.state.game === 6 ? 'active' : ''}`} onClick={() => this.setState({ game: 6 })}>Game 6</div>
                  }
                  {this.state.matchDetailData.games[6] &&
                    <div className={`game-btn ${this.state.game === 7 ? 'active' : ''}`} onClick={() => this.setState({ game: 7 })}>Game 7</div>
                  }
                </div>
              <MatchBreakdown
                team_1_name={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team}
                team_1_kills={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].kills}
                team_1_gold={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].gold_earned}
                team_2_name={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team}
                team_2_kills={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].kills}
                team_2_gold={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].gold_earned}
                winning_team= {this.state.matchDetailData.games[this.state.game - 1].winning_team}
                in_progress={this.state.matchDetailData.in_progress}
                game_duration={this.state.matchDetailData.games[this.state.game - 1].game_duration}
                 />
                 
                <div className="left-container">
                  <div className="header-copy">
                  <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team}.png`} alt={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team} />
                  <h2>{this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team} Stats</h2>
                    <div className="stats-btn" onClick={this.toggleOneStats}>{this.state.hideOne} More Stats</div>
                  </div>
                <div className="stats-container">
                  <PlayerStatsTable
                    headings={[{header_text:'Player_Name'}, {header_text:'Role'}, {header_text:'God'}, {header_text:'Kills',stat:true}, {header_text:'Deaths',stat:true}, {header_text:'Assists',stat:true}, {header_text:'GPM',stat:true}, {header_text:'Relics'}, {header_text:'Final Build'}]}
                    data={this.state.matchDetailData.games[this.state.game - 1]}
                    totalKills={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].kills}
                    totalDeaths={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].deaths}
                    totalAssists={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].assists}
                    team_name={this.state.matchDetailData.games[this.state.game - 1].team_totals[0].team}
                    loadToolTip={this.loadToolTip}
                    toolTipData={this.state.toolTipData}
                    Xposition={this.state.Xposition}
                    Yposition={this.state.Yposition}
                    isLoaded={this.state.isLoaded}
                    />
                    <div className={`additional-stats ${this.state.hideOne === "Hide" ? "" : "hide"}`}>
                      <h2>Additional Player Stats</h2>
                      <StatsTable
                        headings={{name: 'Player Name', role: 'Role', gold: 'Gold', damage_player: 'Damage', damage_mitigated: 'DMG MTGTD', structure_damage: 'STRCT DMG', healing: 'HEALING', wards: 'WARDS'}}
                        data={this.state.matchDetailData.games[this.state.game - 1].players.filter(this.filterTeam).map((playerData) =>
                          ({
                            "name": `${playerData.name}`,
                            "role": `${playerData.role}`,
                            "gpm": `${playerData.gpm}`,
                            "damage": `${playerData.damage_player}`,
                            "dmg_mtgtd": `${playerData.damage_mitigated}`,
                            "strct_dmg": `${playerData.structure_damage}`,
                            "healing": `${playerData.healing}`,
                            "wards": `${playerData.wards}`
                          })
                        )}
                        showIndex={false}
                      />
                    </div>
                    <div className="header-copy two">
                      <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team}.png`} alt={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team} />
                      <h2>{this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team} Stats</h2>
                      <div className="stats-btn" onClick={this.toggleTwoStats}>{this.state.hideTwo} More Stats</div>
                      </div>
                    <PlayerStatsTable
                      headings={[{header_text:'Player_Name'}, {header_text:'Role'}, {header_text:'God'}, {header_text:'Kills',stat:true}, {header_text:'Deaths',stat:true}, {header_text:'Assists',stat:true}, {header_text:'GPM',stat:true}, {header_text:'Relics'}, {header_text:'Final Build'}]}
                      data={this.state.matchDetailData.games[this.state.game - 1]}
                      totalKills={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].kills}
                      totalDeaths={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].deaths}
                      totalAssists={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].assists}
                      team_name={this.state.matchDetailData.games[this.state.game - 1].team_totals[1].team}
                      loadToolTip={this.loadToolTip}
                      toolTipData={this.state.toolTipData}
                      Xposition={this.state.Xposition}
                      Yposition={this.state.Yposition}
                      isLoaded={this.state.isLoaded}
                    />
                    <div className={`additional-stats ${this.state.hideTwo === "Hide" ? "" : "hide"}`}>
                      <h2>Additional Player Stats</h2>
                      <StatsTable
                        headings={{ name: 'Player Name', role: 'Role', gold: 'Gold', damage_player: 'DAMAGE', damage_mitigated: 'DMG MTGTD', structure_damage: 'STRCT DMG', healing: 'HEALING', wards: 'WARDS' }}
                        data={this.state.matchDetailData.games[this.state.game - 1].players.filter(this.filterTeamTwo).map((playerData) =>
                          ({
                            "name": `${playerData.name}`,
                            "role": `${playerData.role}`,
                            "gpm": `${playerData.gpm}`,
                            "damage": `${playerData.damage_player}`,
                            "dmg_mtgtd": `${playerData.damage_mitigated}`,
                            "strct_dmg": `${playerData.structure_damage}`,
                            "healing": `${playerData.healing}`,
                            "wards": `${playerData.wards}`
                          })
                        )}
                        showIndex={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="right-container">
                  <div className="top-players">
                    <TopPlayersCard
                      data={
                        this.state.matchDetailData.games[this.state.game - 1].leaders
                      }
                    />
                  </div>
                  <div className="picks-and-bans">
                    <PicksBansCard
                      team_1_name={this.state.matchDetailData.team_1_name}
                      team_1_score={this.state.matchDetailData.team_1_score}
                      team_2_name={this.state.matchDetailData.team_2_name}
                      team_2_score={this.state.matchDetailData.team_2_score}
                      data={this.state.matchDetailData.games[this.state.game - 1].picks_and_bans}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {this.state.isDataLoaded && this.state.matchDetailData === null &&
          <h1>There are no stats for this match</h1>
        }
      </PageWrapper>
    );
  }
}

export default withContext(Schedule);
