import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './PlayerStatsTable.css';


//Components
import ToolTip from '../../Global/Tooltip/Tooltip';
class PlayerStatsTable extends Component {

  filterTeam = (data) => {
    if (data.team === this.props.team_name) {
      return true;
    }
  }

  render() {
    const {
      headings,
      data,
      totalKills,
      totalAssists,
      totalDeaths,
      toolTipData,
      loadToolTip,
      Xposition,
      Yposition,
      isLoaded
    } = this.props;
    
    return (
      <ComponentWrapper className="c-PlayerStatsTable">
        <div className="grid-wrapper">
          <div className="grid-container">
            {headings && headings.map((head,i) =>
              <div key={i} className={`heading${head.stat?" stat":""}`}>{head.header_text}</div>
            )}
            {data && data.players.filter(this.filterTeam).map((player) =>
              <React.Fragment key={player.name}>
                <div key={player.name} className="item">{player.name}</div>
                <div className="item">{player.role}</div>
                <div className="item">{player.god}</div>
                <div className="item stat">{player.kills}</div>
                <div className="item stat">{player.deaths}</div>
                <div className="item stat">{player.assists}</div>
                <div className="item stat">{player.gpm}</div>
                <div className="item" id="item">
                  {player.relics.map((relic, index) =>
                    <React.Fragment key={relic + index}>
                      {relic !== 'null' &&
                        <img
                          className={index} key={relic + index}
                          onMouseEnter={(e) => loadToolTip(relic, e.clientX, e.clientY, true)}
                          onMouseOut={() => loadToolTip(null, 0, 0, false)}
                          src={`https://webcdn.hirezstudios.com/smite/item-icons/${relic.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-')}.jpg`} alt={relic} />
                      }
                    </React.Fragment>
                  )}
                </div>
                <div className="item">
                  {player.build.map((finalBuild, index) =>
                    <React.Fragment key={finalBuild + index}>
                      {finalBuild !== 'null' &&
                        <img key={index}
                          onMouseOver={(e) => loadToolTip(finalBuild, e.clientX, e.clientY, true)}
                          onMouseOut={() => loadToolTip(null, 0, 0, false)}
                          src={`https://webcdn.hirezstudios.com/smite/item-icons/${finalBuild.toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s+/g, '-')}.jpg`} alt={finalBuild} />
                      }
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
            <div className="item totals"><b>Team Totals</b></div>
            <div className="item totals">-</div>
            <div className="item totals">-</div>
            <div className="item totals stats">{totalKills}</div>
            <div className="item totals stats">{totalDeaths}</div>
            <div className="item totals stats">{totalAssists}</div>
            <div className="item totals stats">-</div>
            <div className="item totals"></div>
            <div className="item totals"></div>
          </div>
          {isLoaded &&
            <ToolTip
              toolTipData={toolTipData}
              Xposition={Xposition}
              Yposition={Yposition}
            />
          }
        </div>
      </ComponentWrapper>
    );
  };
}

PlayerStatsTable.propTypes = {
  data: PropTypes.object,
  totalKills: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  totalDeaths: PropTypes.number,
  totalAssists: PropTypes.number,
  team_name: PropTypes.string,
  headings: PropTypes.array.isRequired,
  toolTipData: PropTypes.object,
  loadToolTip: PropTypes.func,
  Xposition: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired
  ]),
  Yposition: PropTypes.oneOfType([
    PropTypes.bool.isRequired,
    PropTypes.number.isRequired
  ]),
  isLoaded: PropTypes.bool
}

export default PlayerStatsTable;