import styled from 'styled-components';

export const ComponentWrapper = styled.div `
  background: var(--background);
  box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  font-size: 1.4rem;
  overflow: hidden;
  width: 100%;
  h4 {
    border-bottom: .1rem solid var(--border);
    font-family: var(--font-body);
    font-size: 1.8rem;
    font-weight: bold;
    padding: 1.5rem 2rem;
  }
  .grid-wrapper {
    .grid-container {
      align-items: center;
      color: var(--background);
      display: grid;
      grid-template-columns: repeat(1, 2fr) repeat(8, 1fr);
      grid-template-rows: 4rem;
      font-weight: 600;
      justify-content: flex-start;
      text-transform: uppercase;
      width: 100%;
      .item {
        align-items: center;
        border-bottom: 1px solid var(--border);
        color: var(--text);
        display: flex;
        font-size: 1.3rem;
        font-weight: 300;
        min-height: 3.5rem;
        padding: 0 1rem;
        position: relative;
        white-space:nowrap;
        &.totals {
          background: var(--border);
          b {
            font-weight: 600;
            text-transform: initial;
          }
        }
        &.totals.stats{
          text-align: right;
          justify-content:flex-end;
        }
        span {
          font-weight: 600;
          padding: 0 0.5rem;
        }
        img {
          cursor: pointer;
          height: 3rem;
          padding: 0.25rem;
          width: 3rem;
        }
      }
      .item.stat{
        justify-content:flex-end;
      }
      .heading {
        background: var(--border);
        color: var(--text);
        padding: 1.1rem;
      }
      .heading.stat{
        text-align: right;
        justify-content:flex-end;
      }
    }
  }`