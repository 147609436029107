import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';
import PropTypes from 'prop-types';
import { ComponentWrapper } from './MatchBreakdown.css';

const MatchBreakdown = (props) => {
  const { team_1_name, team_1_kills, team_1_gold, team_2_name, team_2_kills, team_2_gold, winning_team, game_duration } = props;

  return (
    <ComponentWrapper className="c-MatchBreakdown">
      <div className="content-wrapper">
        <div className="team-wrapper">
          <div className="stats">
            <strong className={winning_team === 0 ? "" : "lost"}>{team_1_name}</strong>
            <div>{`K: ${team_1_kills}`}</div>
            <div>{`G: ${Number(team_1_gold)?Number(team_1_gold).toLocaleString():team_1_gold}`}</div>
          </div>
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_1_name}.png`} alt={team_1_name} />
        </div>
        <div className="game-result-wrapper">
          <div className="win-loss-wrapper">
            <div className={`team-score ${winning_team === 0 ? 'win' : 'lost'}`}>{winning_team === 0 ? 'W' : 'L'}</div>
            <div className="game-stats">
            </div>
            <div className={`team-score ${winning_team === 1 ? 'win' : 'lost'}`}>{winning_team === 1 ? 'W' : 'L'}</div>
          </div>
          <div className="game-duration">{game_duration}</div>
        </div>
        <div className="team-wrapper">
          <img src={`https://webcdn.hirezstudios.com/smite-esports/dev/teams/${props.context.darkTheme ? 'dark/' : ''}${team_2_name}.png`} alt={team_2_name} />
          <div className="stats">
            <strong className={winning_team === 1 ? "win" : "lost"}>{team_2_name}</strong>
            <div>{`K: ${team_2_kills}`}</div>
            <div>{`G: ${Number(team_2_gold)?Number(team_2_gold).toLocaleString():team_2_gold}`}</div>
          </div>
        </div>
      </div>
    </ComponentWrapper>
  );
};

MatchBreakdown.propTypes = {
  team_1_name: PropTypes.string,
  team_1_kills: PropTypes.number, 
  team_1_gold: PropTypes.number, 
  team_2_name: PropTypes.string,
  team_2_kills: PropTypes.number,
  team_2_gold: PropTypes.number,
  in_progress: PropTypes.number,
  winning_team: PropTypes.number
}

export default withContext(MatchBreakdown);