import styled from 'styled-components';

export const ComponentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 156.6rem;
  min-height: 15rem;
  width: 100%;
  @media only screen and (max-width: 768px) {
    min-height: 15rem;
  }
  .content-wrapper {
    display: flex;
  }
  .team-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    .lost {
      color: var(--secondary-text);
    }
    .stats {
       @media only screen and (max-width: 768px) {
         margin: 1rem;
       }
    }
    strong {
      color: var(--text);
      display: block;
      font-size: 2.6rem;
      font-weight: bold;
      margin-bottom: 1rem;
      @media only screen and (max-width: 768px) {
        font-size: 1.6rem;
      }
    }
    div {
      color: var(--secondary-text);
      font-size: 1.6rem;
      font-weight: bold;
      padding: 0.2rem 0;
      @media only screen and (max-width: 768px) {
        font-size: 1.6rem;
      }
    }
    img {
      margin: 0;
      height: 8.5rem;
      object-fit: contain;
      width: 12.5rem;
      @media only screen and (max-width: 768px) {
        height: 7.5rem;
        margin: 0 auto;
        width: 7.5rem;
      }
    }
  }
  .team-score {
    align-items: center;
    display: flex;
    font-size:  3.8rem;
    font-weight: bold;
    justify-content: center;
    margin: 0 1rem;
    @media only screen and (max-width: 768px) {
      font-size: 1.8rem;
      margin: 0 1rem;
    }
    &.lost {
      color: var(--secondary-text);
    }
    &.win {
      color: var(--text);
    }
  }
  .game-result-wrapper{
    display:flex;
    flex-direction:column;
    justify-content: center;
  }
  .win-loss-wrapper{
    display:flex;
    flex-direction:row;
    margin-bottom: 1rem;
  }
  .game-duration{
    font-size:1.6rem;
    text-align:center;
    text-transform: uppercase;
    font-weight:700;
    color: var(--text);
  }
  .game-stats {
    color: var(--text);
    display: flex;
    flex-direction: column;
    font-size: 2.4rem;
    font-weight: bold;
    justify-content: center;
    min-width: 3.5rem;
    text-align: center;
    text-transform: uppercase;
    strong {
      display: block;
       @media only screen and (max-width: 768px) {
         font-size: 1.2rem;
       }
    }
  }
`;