import styled from 'styled-components';

const PageWrapper = styled.div `
  min-height: 100vh;
  padding: 1.3rem 0 0;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
  .match-page-container {
    background-color: var(--background);
    box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
    color: var(--text);
    margin: 0 auto;
    max-width: 146.6rem;
    @media only screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .center-wrapper {
    background-color: var(--background);
    color: var(--text);
    margin: 0 auto;
    max-width: 156.6rem;
    padding: 0;
  }
  h1 {
    color: var(--text);
    font-size: 3.4rem;
    margin: 5rem;
    text-transform: uppercase;
    text-align: center;
  }
   h2 {
    font-size: 1.8rem;
    padding: 1.6rem;
    text-align: left;
    text-transform: uppercase;
  }
  .hide {
    display: none;
  }
  .components-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 142.4rem;
    margin: 0 auto;
    width: 100%;
     @media only screen and (max-width: 768px) {
       .game-container {
        background: var(--border);
         order: 1;
       }
       .left-container {
         order: 3;
       }
       .right-container {
         padding-top: 2rem;
         order: 2;
       }
     }
    .game-container {
      align-items: center;
      display: flex;
      flex-wrap: wrap
      justify-content: center;
      padding: 2rem;
      width: 100%;
      @media only screen and (max-width: 768px) {
        padding: 1rem;
      }
      .game-btn {
        align-items: center;
        background: var(--border);
        cursor: pointer;
        display: flex;
        font-weight: 600;
        height: 4rem;
        justify-content: center;
        margin: 0.5rem;
        text-transform: uppercase;
        width: 12.5rem;
        @media only screen and (max-width: 768px) {
          background: var(--secondary-background);
        }
        &.active {
          background: var(--text);
          color: var(--background);
        }
      }
    }
    .left-container, .header-copy {
      max-width: 100rem;
      width: 100%;
    }
    .left-container {
      margin-right: 3rem;
      position: relative;
      z-index: 1;
    }
    .header-copy {
      align-items: center;
      box-shadow: 0 0.3rem 0.6rem rgba(0,0,0,0.16);
      display: flex;
      position: relative;
      width: 100%;
      &.two {
        margin-top: 2rem;
      }
      img {
          max-width: 2rem;
          margin-left: 1rem;
        }
      .stats-btn {
        border-bottom: 1px solid;
        color: var(--text);
        cursor: pointer;
        margin-right: 2rem;
        right: 1rem;
        position: absolute;
        @media only screen and (max-width: 768px) {
          background: #F1F1F1;
          border-bottom: none;
          padding: 1rem;
        }
      } 
    }
    .right-container {
      max-width: 38.2rem;
      width: 100%;
      .top-players {
        margin: 2.5rem 0;
      }
    }
    .right-container, .left-container {
      @media only screen and (max-width: 1423px) {
        max-width: initial;
         margin-right: initial;
      }
    }

    .additional-stats {
      h2 {
        box-shadow: 0px 0.3rem 0.6rem rgba(0,0,0,0.16);
      }
    }
    .c-PlayerStatsTable, .c-StatsTable {
      overflow-x: scroll;
    }
    .c-StatsTable {
      thead {
        background: var(--border);
        th {
          color: var(--text);
        }
      }
    }
    .footer-container {
      display: flex;
      justify-content: center;
      padding-bottom: 2rem;
      width: 100%;
      flex-wrap: wrap;
    }
  }
`;

export default PageWrapper;